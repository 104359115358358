import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/mrunalini.jpg';

const AdvocateMrunaliniDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Ms. Mrunalini completed her LLB from Pendekanti law College
                affiliated to Osmania University, Hyderabad. She also did her
                LLM from Andhra Mahila Sabha, Osmania University. She enrolled
                as an advocate in the year 2019. She is specialised in the areas
                of civil laws. She is currently practising at Ranga Reddy Courts
                and City Civil Courts.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Mrunalini Eitikela, LLM</h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateMrunaliniDetails;
